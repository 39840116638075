import { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/ChatInterface.css';
import { API_URL } from '../utils/config';

const ChatInterface = ({ onComplete }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messagesContainerRef = useRef(null);
    const [isComplete, setIsComplete] = useState(false);
    const messageIdCounter = useRef(1);
    const autoMessageSentRef = useRef(false);
    const initialMessageSentRef = useRef(false);

    const getNextMessageId = () => {
        const nextId = messageIdCounter.current;
        messageIdCounter.current += 1;
        return nextId;
    };

    const handleSendMessage = useCallback(async (messageToSend) => {
        if (isTyping) return;
        
        console.log('handleSendMessage called with:', messageToSend);
        const messageText = messageToSend || inputMessage;
        if (!messageText.trim()) return;
        
        console.log('Processing message:', messageText);
        setInputMessage('');
        
        const userMessage = {
            id: getNextMessageId(),
            text: messageText,
            sender: 'user'
        };

        console.log('Adding user message:', userMessage);
        setMessages(prev => [...prev, userMessage]);
        setIsTyping(true);

        try {
            console.log('Sending message to bot with history:', [...messages, userMessage]);
            const { message, isComplete: chatComplete } = await getBotResponse([...messages, userMessage]);
            console.log('Received bot response:', message, 'Complete:', chatComplete);
            
            if (chatComplete) {
                // Store the full conversation including the final bot message
                const fullConversation = [...messages, userMessage, {
                    id: getNextMessageId(),
                    text: message,
                    sender: 'bot'
                }].map(msg => `${msg.sender === 'user' ? 'User' : 'Assistant'}: ${msg.text}`).join('\n');

                // Show a different message to the user
                const finalMessage = {
                    id: getNextMessageId(),
                    text: "Data gathering complete. Generating video...",
                    sender: 'bot'
                };
                
                setMessages(prev => [...prev, finalMessage]);
                setIsComplete(true);
                console.log('Sending full conversation:', fullConversation);
                onComplete(fullConversation);
            } else {
                // Normal bot message
                const botMessage = {
                    id: getNextMessageId(),
                    text: message,
                    sender: 'bot'
                };
                
                console.log('Adding bot message:', botMessage);
                setMessages(prev => [...prev, botMessage]);
            }
        } catch (error) {
            console.error('Error in handleSendMessage:', error);
        } finally {
            setIsTyping(false);
        }
    }, [messages, inputMessage, onComplete, isTyping]);

    // Initialize chat
    useEffect(() => {
        if (initialMessageSentRef.current) return;
        initialMessageSentRef.current = true;

        console.log('Setting initial message');
        localStorage.removeItem('chatHistory');
        localStorage.removeItem('chatComplete');
        
        const initialMessage = {
            id: getNextMessageId(),
            text: "Hi! I'm your AI assistant, ready to help you create an amazing video invitation. Tell me about the event you're planning!",
            sender: 'bot'
        };
        
        setMessages([initialMessage]);
    }, []);

    // Send automatic message
    useEffect(() => {
        const sendAutoMessage = async () => {
            if (autoMessageSentRef.current || messages.length === 0) return;
            if (messages.length === 1 && messages[0].sender === 'bot') {
                autoMessageSentRef.current = true;
                await new Promise(resolve => setTimeout(resolve, 1000));
                await handleSendMessage("Hello");
            }
        };

        sendAutoMessage();
    }, [messages, handleSendMessage]);

    // Save messages to localStorage whenever they change
    useEffect(() => {
        if (messages.length > 0) {
            console.log('Saving messages to localStorage:', messages);
            localStorage.setItem('chatHistory', JSON.stringify(messages));
            localStorage.setItem('chatComplete', isComplete.toString());
        }
    }, [messages, isComplete]);

    // Auto scroll to bottom when new messages arrive
    useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTo({
                top: messagesContainerRef.current.scrollHeight,
                behavior: 'smooth', // Optional for smooth scrolling
            });
        }
    }, [messages]);

    const getBotResponse = async (messageHistory) => {
        console.log('Getting bot response for:', messageHistory);
        try {
            const response = await fetch(`${API_URL}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    messages: messageHistory.map(msg => ({
                        role: msg.sender === 'user' ? 'user' : 'assistant',
                        content: msg.text
                    }))
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Bot response data:', data);
            return {
                message: data.message,
                isComplete: data.isComplete
            };
        } catch (error) {
            console.error('Error in getBotResponse:', error);
            return {
                message: "I'm sorry, I encountered an error. Please try again.",
                isComplete: false
            };
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            handleSendMessage();
        }
    };

    const clearChat = () => {
        console.log('Clearing chat');
        localStorage.removeItem('chatHistory');
        localStorage.removeItem('chatComplete');
        messageIdCounter.current = 1;
        autoMessageSentRef.current = false;
        initialMessageSentRef.current = false;
        setIsComplete(false);
        setMessages([]);
    };

    return (
        <div className="chat-container">
            <div className="chat-header">
                <h2>Event Planning Assistant</h2>
                <button onClick={clearChat} className="clear-button">
                    Clear Chat
                </button>
            </div>
            
            <div className="messages-container" ref={messagesContainerRef}>
                {messages.map((message) => (
                    <div
                        key={message.id}
                        className={`message ${message.sender === 'bot' ? 'bot' : 'user'}`}
                    >
                        {message.text}
                    </div>
                ))}
                {isTyping && (
                    <div className="message bot typing">
                        <div className="typing-indicator">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                )}
                {/* Remove this div if not needed */}
                {/* <div ref={messagesEndRef} /> */}
            </div>

            <div className="input-container">
                <textarea
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message..."
                    rows={1}
                    disabled={isComplete || isTyping}
                />
                <button 
                    onClick={() => handleSendMessage()}
                    disabled={!inputMessage.trim() || isTyping || isComplete}
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default ChatInterface;