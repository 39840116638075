import { useState, useEffect, useRef } from 'react';
import './App.css';
import ChatInterface from './components/ChatInterface';
import { API_URL } from './utils/config';

function App() {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [videos, setVideos] = useState([]);
  const [loadingVideos, setLoadingVideos] = useState(true);
  const videoSectionRef = useRef(null);
  const contentRef = useRef(null);

  const fetchVideos = async () => {
    try {
      const response = await fetch(`${API_URL}/videos`);
      const data = await response.json();
      if (data.status === 'success') {
        setVideos(data.data.videos);
      }
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setLoadingVideos(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleChatComplete = async (conversation) => {
    setPrompt(conversation);
    if (window.innerWidth <= 1024) {
      const scrollOptions = {
        behavior: 'smooth',
        block: 'start',
      };

      document.body.style.overflow = 'hidden';
      
      setTimeout(() => {
        videoSectionRef.current?.scrollIntoView(scrollOptions);
        setTimeout(() => {
          document.body.style.overflow = '';
        }, 1000);
      }, 100);
    }
  };

  useEffect(() => {
    const preventDefaultScroll = (e) => {
      if (window.innerWidth <= 1024 && e.target.tagName === 'TEXTAREA') {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    document.addEventListener('scroll', preventDefaultScroll, { passive: false });
    return () => document.removeEventListener('scroll', preventDefaultScroll);
  }, []);

  const handleGenerateVideo = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/generate-video`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          prompt: prompt
        }),
      });

      const data = await response.json();
      setResponse(data);
      await fetchVideos();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (prompt) {
      handleGenerateVideo();
    }
  }, [prompt]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(parseInt(timestamp) * 1000);
    return date.toLocaleString();
  };

  const VideoPlayer = ({ src, className }) => {
    const videoRef = useRef(null);
    const [error, setError] = useState(false);
  
    const handleError = () => {
      console.error('Error loading video:', src);
      setError(true);
    };
  
    return error ? (
      <div className="video-error">
        <p>Error loading video</p>
        <button onClick={() => setError(false)}>Retry</button>
      </div>
    ) : (
      <video
        ref={videoRef}
        controls
        className={className}
        onError={handleError}
        preload="metadata"
      >
      
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };
  

  return (
    <div className="App" ref={contentRef}>
      <header className="App-header">
        <h1>Event Trailer Generator</h1>
        
        <div className="content-container">
          <div className="chat-section">
            <ChatInterface onComplete={handleChatComplete} />
          </div>
          
          <div 
            className="video-section" 
            ref={videoSectionRef}
            id="video-section"
          >
            {loading ? (
              <div className="video-skeleton">
                <div className="skeleton-animation">
                  <div className="skeleton-header"></div>
                  <div className="skeleton-video"></div>
                  <div className="skeleton-details">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                </div>
              </div>
            ) : response ? (
              <div className="video-container">
                <h2>Your Video is Ready!</h2>
                <div className="video-player">
                  {videos.length > 0 && (
                    <VideoPlayer 
                      src={videos[0].path}
                      className="current-video"
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="empty-state">
                <h2>Video Preview</h2>
                <p>Complete the chat to generate your video</p>
              </div>
            )}
          </div>
        </div>

        <div className="previous-videos-section">
          <div className="previous-videos-header">
            <h2>Previous Videos</h2>
            <button 
              onClick={fetchVideos} 
              className="refresh-button"
              disabled={loadingVideos}
            >
              Refresh Videos
            </button>
          </div>
          {loadingVideos ? (
            <div className="gallery-skeleton">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="gallery-item-skeleton" />
              ))}
            </div>
          ) : (
            <div className="gallery-grid">
              {videos.map((video, index) => (
                <div key={index} className="gallery-item">
                  <VideoPlayer 
                    src={`https://spearheads.eu${video.path}`}
                    className="thumbnail"
                  />
                  <div className="gallery-item-info">
                    
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
  